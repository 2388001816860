import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faSignOutAlt, faTable, faTh } from '@fortawesome/free-solid-svg-icons';
import { TvfAuthService } from 'src/app/service/tvf-auth.service';

@Component({
  selector: 'app-portal-header',
  templateUrl: './portal-header.component.html',
  styleUrls: ['./portal-header.component.css']
})
export class PortalHeaderComponent implements OnInit {

  faTable = faTable;
  faTh = faTh;
  faSignOut = faSignOutAlt;

  constructor(
    private tvfAuthService: TvfAuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  signout(): void {
    this.tvfAuthService.logout();
    this.router.navigate(['/login']);
  }
}
