<ul nz-menu nzMode="horizontal" style="text-align:center">
  <li 
    nz-menu-item
    routerLinkActive='ant-menu-item-selected'
    [routerLinkActiveOptions]="{exact:true}">
    <a routerLink="/epg/tile" title="Channels">
      <fa-icon [icon]="faTh" class="fa-2x"></fa-icon>
    </a>
  </li>
 <li 
  nz-menu-item
  routerLinkActive='ant-menu-item-selected'
  [routerLinkActiveOptions]="{exact:true}">
    <a routerLink="/epg/grid" title="EPG-Grid">
      <fa-icon [icon]="faTable" class="fa-2x"></fa-icon>
    </a>
  </li>
  <li
    nz-menu-item
  >
    <a (click)="signout()" title="Sign out">
      <fa-icon [icon]="faSignOut" class="fa-2x"></fa-icon>
    </a>
  </li>
</ul>