/**
 * @author Johann Kowalski (traal-devel) <devel@traal.ch>
 */


import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { HlsStreamPipe } from 'src/app/shared/pipe/hls-stream.pipe';
import { EpgService } from 'src/app/service/epg.service';
import { TvfAuthService } from 'src/app/service/tvf-auth.service';
import { AssetItem, AssetsListResponse, ChannelStreamResponse, EpgListResponse } from 'tvf-rest-client';
import * as Plyr from 'plyr';
import * as Hls from 'hls.js';
import { ChannelProgram } from 'projects/tvf-rest-client/src';

@Component({
  selector: 'app-epg-tile',
  templateUrl: './epg-tile.component.html',
  styleUrls: ['./epg-tile.component.css']
})
export class EpgTileComponent implements OnInit {


  /* member variables */
  detailIndex = -1;

  channelList = [];
  epgCurrentList: ChannelProgram[];

  player: Plyr = null;

  /* constructors */
  constructor(
    private tvfAuthService: TvfAuthService,
    private epgService: EpgService,
    private hlsStreamPipe: HlsStreamPipe,
  ) { }


  /* methods */
  ngOnInit(): void {
    // ensure that access token is present in the http headers
    this.tvfAuthService.checkAutoLogin();

    this.initEpgList();
  }

  initEpgList(): void {
    this.epgService
      .getChannelList()
      .subscribe((value: AssetsListResponse) => {
        this.channelList = [
          ...this.channelList,
          ...value.data,
        ];
      }
      );
  }

  closeChannel(): void {
    this.channelList.splice(this.detailIndex, 1);
    this.detailIndex = -1;
  }

  showChannel(index: number): void {
    const tmpRef = this.channelList[index];
    if (this.detailIndex > -1) {
      this.channelList.splice(this.detailIndex, 1);
      index = index - 1;
    }
    this.detailIndex = (Math.floor((index) / 8)) * 8;
    this.channelList.splice(this.detailIndex, 0, tmpRef);
    this.channelList = [
      ...this.channelList,
    ];

    // Workaround: Wait, till video element is added to dom tree.
    setTimeout(() => {
      const video = document.querySelector('video') as HTMLVideoElement;
      this.initPlyr(
        this.channelList[this.detailIndex],
        video
      );
    });
    this.initEpg();
  }

  private initEpg(): void {
    this.epgService
      .getNextThreeLiveEvents(
        this.channelList[this.detailIndex].id
      )
      .subscribe((value: EpgListResponse) => {
        this.epgCurrentList = value.data;
      });
  }


  private initPlyr(
    assetItem: AssetItem,
    video: HTMLVideoElement
  ): void {
    const rHlsStream = this.hlsStreamPipe.transform(assetItem.resources);

    this.player = new Plyr(video, {
      captions: {
        active: true,
        update: true,
        language: 'en'
      },
      controls: [
        'play-large', // The large play button in the center
        'play', // Play/pause playback
        // 'duration', // The full duration of the media
        'mute', // Toggle mute
        'volume', // Volume control
        'captions', // Toggle captions
        'settings', // Settings menu
        'fullscreen', // Toggle fullscreen
      ]
    });


    if (!Hls.isSupported()) {
      alert('HLS is NOT supported');
    } else {
      this.epgService.getChannelStream(
        assetItem.id,
        rHlsStream.id
      ).subscribe((response: ChannelStreamResponse) => {
        const hlsOptions = { xhrSetup: xhr => { } };
        const hls = new Hls(hlsOptions);
        hls.loadSource(response.data);
        hls.attachMedia(video);
        // Handle changing captions
        this.player.on('languagechange', () => {
          // Caption support is still flaky. See: https://github.com/sampotts/plyr/issues/994
          setTimeout(() => hls.subtitleTrack = this.player.currentTrack, 50);
        });
      });
    }
  }
}
