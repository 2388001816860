/**
 * @author Johann Kowalski (traal-devel) <devel@traal.ch>
 */


import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { interval } from 'rxjs';
import { TimeDifferenceWidthPipe } from 'src/app/shared/pipe/time-difference-width.pipe';
import { EpgService } from 'src/app/service/epg.service';
import { TvfAuthService } from 'src/app/service/tvf-auth.service';
import { AssetItem, AssetsListResponse, ChannelProgram, ChannelStreamResponse, EpgListResponse } from 'tvf-rest-client';
import { HlsStreamPipe } from 'src/app/shared/pipe/hls-stream.pipe';
import * as Plyr from 'plyr';
import * as Hls from 'hls.js';

// Map.prototype.insertAtIndex = function(index, key, value, map){
//   const arr = Array.from(map);
//   arr.splice(index, 0, [key, value]);
//   return new Map(arr);
// };

enum FlagChannelMode { INSERT, UPDATE }

@Component({
  selector: 'app-epg-grid',
  templateUrl: './epg-grid.component.html',
  styleUrls: ['./epg-grid.component.css']
})
export class EpgGridComponent implements OnInit, AfterViewChecked {


  /* member variables */
  private source = interval(30000);
  private blInitViewPort = false;

  detailIndex = -1;
  showVideo = false;

  currentPosition = -10;
  currentLabel = '';
  private offset = -144; // 144;


  @ViewChild('epgWrapper', { static: false }) epgWrapperRef: ElementRef;


  ele = null;
  pos = { top: 0, left: 0, x: 0, y: 0 };

  dataHourLabel = [
    '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00',
    '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00',
    '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '00:00'
  ];


  channelMap = {};
  epgDataList = [];
  currentChannelList = [];
  epgCurrentList: ChannelProgram[];

  player: Plyr = null;



  /* constructors */
  constructor(
    private tvfAuthService: TvfAuthService,
    private epgService: EpgService,
    private hlsStreamPipe: HlsStreamPipe,
  ) { }


  /* methods */
  ngOnInit(): void {
    // ensure that access token is present in the http headers
    this.tvfAuthService.checkAutoLogin();

    this.setCurrentPosition();
    this.source.subscribe((value: number) => {
      this.setCurrentPosition();
    });

    this.initEpgGrid();
    this.initChannelList();
  }

  /**
   * Workaound: we cant not use ngAfterView.
   */
  ngAfterViewChecked(): void {
    if (!this.blInitViewPort) {
      this.initViewPort();
      this.blInitViewPort = true;
    }
  }

  initViewPort(): void {
    this.epgWrapperRef.nativeElement.scrollTo({
      left: this.currentPosition - (TimeDifferenceWidthPipe.VIEWPORT_WIDTH / 2), behavior: 'smooth'
    });
  }

  setCurrentPosition(): void {
    const currentDate = new Date();
    const currentMinutes = currentDate.getHours() * 60 + currentDate.getMinutes() ;
    this.updateCurrentTime(currentDate);
    this.currentPosition = ((currentMinutes / TimeDifferenceWidthPipe.SMALL_UNIT_IN_MIN)
      * TimeDifferenceWidthPipe.SMALL_UNIT_IN_PX + this.offset);

    // for (let obj of this.currentChannelList) {
    //   this.flagAsCurrentChannel(currentDate, obj, FlagChannelMode.UPDATE);
    // }

  }

  updateCurrentTime(currentDate: Date): void {
    const minutesLabel = (currentDate.getMinutes() < 10 ?
      '0' : '') + currentDate.getMinutes();
    this.currentLabel = currentDate.getHours() + ':' + minutesLabel;

  }

  private initEpgGrid(): void {
    const dCurrentDate = new Date();
    const myFormattedDate = this.epgService.formatDateShort(dCurrentDate);
    this.epgService
      .getEpgOverviewList(dCurrentDate)
      .subscribe((retValue: EpgListResponse) => {
        const tmpEpgMap = retValue.data.reduce((map, obj) => {
          if (map[obj.live_id] == null) {
            map[obj.live_id] = [];
          }
          if (!obj.start_time.startsWith(myFormattedDate)) {
            obj.start_time = myFormattedDate + 'T00:00:00Z';
          }

          if (!obj.end_time.startsWith(myFormattedDate)) {
            obj.end_time = myFormattedDate + 'T23:59:59Z';
          }

          this.flagAsCurrentChannel(dCurrentDate, obj, FlagChannelMode.INSERT);

          map[obj.live_id].push(obj);
          return map;
        }, {} as Map<number, ChannelProgram>);

        const tmpEpgArray = Object.values(tmpEpgMap);
        tmpEpgArray.sort((a: Array<ChannelProgram>, b: Array<ChannelProgram>) => {
          return this.channelMap[a[0].live_id].lcn < this.channelMap[b[0].live_id].lcn ? -1 : 1;
        });

        this.epgDataList = Object.values(tmpEpgArray);
      });

  }

  flagAsCurrentChannel(
    dCurrentDate: Date,
    epgProgram: ChannelProgram,
    mode: FlagChannelMode
  ): void {
    const dStartTime = new Date(epgProgram.start_time);
    const dEndTime = new Date(epgProgram.end_time);

    const now = dCurrentDate.getTime();
    if (now >= dStartTime.getTime() && now < dEndTime.getTime()) {
      this.currentChannelList.push(epgProgram);
    }
    //  else if (FlagChannelMode.UPDATE === mode) {
    //   // // remove from current channel list
    //   // const idx = this.currentChannelList.indexOf(epgProgram);
    //   // this.currentChannelList.splice(idx, idx);
    //   // console.log("Remove: ", idx);

    //   // const arrEpgChannel = this.dataEpgMap[epgProgram.live_id];
    //   // const newIdx = arrEpgChannel.indexOf(epgProgram) + 1;
    //   // console.log("New: ", idx);
    //   // if (newIdx < arrEpgChannel.length) {
    //   //   this.currentChannelList.push(arrEpgChannel[newIdx]);
    //   // }
    // } else {
    // }
  }


  initChannelList(): void {
    this.epgService.getChannelList().subscribe((retValue: AssetsListResponse) => {
      this.channelMap = retValue.data.reduce((map, obj) => {
        map[obj.id] = obj;
        return map;
      }, {});
    });
  }

  showChannel(
    index: number,
    epgChannel: ChannelProgram
  ): void {
    const tmpRef = this.epgDataList[index];

    // delete detail
    if (this.detailIndex > -1) {
      this.epgDataList.splice(this.detailIndex, 1);
      index = index - 1;
    }
    console.log('show-channel', index);

    this.detailIndex = index + 1;
    this.epgDataList.splice(this.detailIndex, 0, tmpRef);
    this.epgDataList = [
      ...this.epgDataList
    ];

    // Currently Live ... show video stream.
    // Workaround: Wait, till video element is added to dom tree.
    if (this.currentChannelList.indexOf(epgChannel) !== -1) {
      setTimeout(() => {
        const video = document.querySelector('video') as HTMLVideoElement;
        const epgChannels = this.epgDataList[this.detailIndex];
        const idx = epgChannels.indexOf(epgChannel);
        const channel = this.channelMap[epgChannels[idx].live_id];
        this.initPlyr(
          channel,
          video
        );
      });
      this.showVideo = true;
    } else {
      this.showVideo = false;
    }
    this.initEpg(epgChannel);
  }

  closeChannel(): void {
    this.epgDataList.splice(this.detailIndex, 1);
    this.detailIndex = -1;
  }

  private initEpg(
    epgChannel: ChannelProgram
  ): void {
    this.epgService
      .getLiveEventAt(
        epgChannel.live_id,
        epgChannel.start_time
      )
      .subscribe((value: EpgListResponse) => {
        this.epgCurrentList = value.data;
      });
  }

  private initPlyr(
    assetItem: AssetItem,
    video: HTMLVideoElement
  ): void {
    const rHlsStream = this.hlsStreamPipe.transform(assetItem.resources);

    this.player = new Plyr(video, {
      captions: {
        active: true,
        update: true,
        language: 'en'
      },
      controls: [
        'play-large', // The large play button in the center
        'play', // Play/pause playback
        // 'duration', // The full duration of the media
        'mute', // Toggle mute
        'volume', // Volume control
        'captions', // Toggle captions
        'settings', // Settings menu
        'fullscreen', // Toggle fullscreen
      ]
    });


    if (!Hls.isSupported()) {
      alert('HLS is NOT supported');
    } else {
      this.epgService.getChannelStream(
        assetItem.id,
        rHlsStream.id
      ).subscribe((response: ChannelStreamResponse) => {
        const hlsOptions = { xhrSetup: xhr => { } };
        const hls = new Hls(hlsOptions);
        hls.loadSource(response.data);
        hls.attachMedia(video);
        // Handle changing captions
        this.player.on('languagechange', () => {
          // Caption support is still flaky. See: https://github.com/sampotts/plyr/issues/994
          setTimeout(() => hls.subtitleTrack = this.player.currentTrack, 50);
        });
      });
    }
  }

  // https://github.com/phuoc-ng/html-dom/blob/master/demo/drag-to-scroll/index.html
  mouseDownHandler(e): void {
    console.log('mouse-down-handler');
    this.ele = document.getElementById('epgWrapper');
    this.ele.style.cursor = 'grabbing';
    this.ele.style.userSelect = 'none';

    this.pos = {
      left: this.ele.scrollLeft,
      top: this.ele.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener('mousemove', this.mouseMoveHandler);
    document.addEventListener('mouseup', this.mouseUpHandler);
  }

  mouseMoveHandler(e): void {
    console.log('mouse-move-handler', this.pos);
    this.ele = document.getElementById('epgWrapper');
    // How far the mouse has been moved
    const dx = e.clientX - this.pos.x;
    const dy = e.clientY - this.pos.y;

    // Scroll the element
    this.ele.scrollTop = this.pos.top - dy;
    this.ele.scrollLeft = this.pos.left - dx;
  }

  mouseUpHandler(e): void {
    console.log('mouse-up-handler');
    this.ele = document.getElementById('epgWrapper');
    this.ele.style.cursor = 'grab';
    this.ele.style.removeProperty('user-select');

    document.removeEventListener('mousemove', this.mouseMoveHandler);
    document.removeEventListener('mouseup', this.mouseUpHandler);
  }
}
