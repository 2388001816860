/**
 * @author Johann Kowalski (traal-devel) <devel@traal.ch>
 */


import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssetsListResponse, AssetsService, ChannelStreamResponse, DataParam, EpgListResponse, LiveEventsService, QueryParamMap } from 'tvf-rest-client';
import { TvfAuthService } from './tvf-auth.service';

@Injectable({
  providedIn: 'root'
})
export class EpgService {


  /* member variables */


  /* constructors */
  constructor(
    private assetsService: AssetsService,
    private liveEventService: LiveEventsService,
    private datePipe: DatePipe,
  ) { }


  /* methods */
  getChannelList(): Observable<AssetsListResponse> {
    const filter = new DataParam();
    filter.$expand = '*';
    filter.$limit = 100;
    filter.$offset = 0;
    filter.$orderby = 'lcn';
    // filter.$match = 'SRF1';

    const params: QueryParamMap = new QueryParamMap();
    params.set('type', { eq: 'channel' });
    params.set('categories.ext_id', { eq: 'tv' });

    return this.assetsService.getAssetsList(
      filter,
      params,
      'en'
    );
  }

  getChannelStream(channelId: number, streamId: number): Observable<ChannelStreamResponse> {
    return this.assetsService.getChannelStream(channelId, streamId);
  }

  getNextThreeLiveEvents(
    channelId: number
  ): Observable<EpgListResponse> {
    const filter = new DataParam();
    filter.$expand = 'thumbnail(url),program';
    filter.$limit = 3;
    // filter.$match = 'SRF1';

    const params: QueryParamMap = new QueryParamMap();
    params.set('type', { eq: 'channel' });
    // params.set('start_time', { gt: new Date().toISOString() });
    params.set('end_time', { gt: new Date().toISOString() });
    params.set('live.id', { eq: channelId + '' });

    return this.liveEventService.getEpgList(
      filter,
      params,
      'en'
    );
  }


  getLiveEventAt(
    channelId: number,
    endTime: string,
  ): Observable<EpgListResponse> {
    const filter = new DataParam();
    filter.$expand = 'thumbnail(url),program';
    filter.$limit = 1;
    filter.$orderby = 'start_time';

    const tmpEndDate = this.datePipe.transform(endTime, 'yyyy-MM-ddTHH:mm:ssZ', 'UTC', 'de-ch');
    const params: QueryParamMap = new QueryParamMap();
    params.set('type', { eq: 'channel' });
    // params.set('start_time', { gt: new Date().toISOString() });
    params.set('end_time', { gt: tmpEndDate.replace('+0000', 'Z') }); // :INFO: Workaround - Backend can not handle utc timezone in string. 
    params.set('live.id', { eq: channelId + '' });

    return this.liveEventService.getEpgList(
      filter,
      params,
      'en'
    );
  }

  getEpgOverviewList(
    pRefDate: Date
  ): Observable<EpgListResponse> {
    const filter = new DataParam();
    // filter.$expand = 'program';
    filter.$orderby = 'start_time';
    // filter.$limit = 1;

    const dYestarday = new Date();
    dYestarday.setDate(pRefDate.getDate() - 1);
    const dYesterdayFormatted = this.formatDateShort(dYestarday);

    const dTomorrow = new Date();
    dTomorrow.setDate(pRefDate.getDate() + 1);
    const dTomorrowFormatted = this.formatDateShort(dTomorrow);

    const params: QueryParamMap = new QueryParamMap();
    params.set('type', { eq: 'channel' });
    params.set('start_time', { lt: dTomorrowFormatted + 'T00:00:00Z' });
    params.set('end_time', { gt: dYesterdayFormatted + 'T23:59:59Z' }); // 2020-11-02T15:04:27.169Z"^
    // params.set('live.id', {in: '382749'});
    // params.set('live.id', {in: '382749,382752,382859,382861,382866,382873'});

    return this.liveEventService.getEpgList(
      filter,
      params,
      'en'
    );
  }

  formatDateShort(pDate: Date): string {
    return this.datePipe.transform(pDate, 'yyyy-MM-dd');
  }
}
